
































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem51LBPostLab5IntroTheory',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        introductionAnswer: null,
        theoryAnswer: null,
      },
      questions: [
        {
          text: 'In the space below, please write your introduction.',
          inputName: 'introductionAnswer',
          maxInputLength: 1000,
        },
        {
          text: 'In the space below, please write your theory.',
          inputName: 'theoryAnswer',
          maxInputLength: 4000,
        },
      ],
    };
  },
});
